<template>
  <td>
    <!-- @slot Body-Cell-Inhalt -->
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "LkTableBodyCell"
};
</script>

<style>
</style>