<template>
  <div>
    <LkNavbar>
      <template v-slot:headline>Barcode Reader</template>
      <template v-slot:navbar-items>
        <LkNavbarItem>
          <div class="form-inline">
            <input
              class="form-control mr-sm-2 mt-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              v-model="searchInput"
            />
          </div>
        </LkNavbarItem>
      </template>
    </LkNavbar>

      <LkTable style="margin-bottom:100px">
        <template v-slot:table-header>
          <LkTableHeaderCell>Bezeichnung</LkTableHeaderCell>
          <LkTableHeaderCell>Anzahl</LkTableHeaderCell>
          <LkTableHeaderCell></LkTableHeaderCell>
        </template>
        <template v-slot:table-body>
          <LkTableBodyRow
            v-for="artikels in filteredArtikel"
            :key="artikels.id"
          >
            <LkTableBodyCell
              ><template v-if="artikels.objekt">{{
                artikels.objekt.bezeichnung
              }}</template></LkTableBodyCell
            >

            <LkTableBodyCell>{{ artikels.anzahl }}</LkTableBodyCell>
            <LkTableBodyCell>
              <button
                class="btn btn-primary"
                @click="showAnzahlModal(artikels)"
              >
                <svg
                  version="1.1"
                  id="IconsRepoEditor"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 528.899 528.899"
                  style="enable-background: new 0 0 528.899 528.899"
                  xml:space="preserve"
                  fill="#ffffff"
                  stroke="#ffffff"
                  stroke-width="0"
                >
                  <path
                    d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981 c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611 C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069 L27.473,390.597L0.3,512.69z"
                  />
                </svg>
              </button>
            </LkTableBodyCell>
          </LkTableBodyRow>
        </template>
      </LkTable>

    <LkFooter>
      <router-link
        to="/addProduct"
        class="col-3 offset-9 btn.container"
      >
        <button type="button" class="btn btn-primary btn-circle btn-md mb-1">
          Scan
        </button>
      </router-link>
    </LkFooter>

    <LkModal v-if="showModal">
      <template v-slot:header>
        <h3>Anzahl bearbeiten</h3>
      </template>
      <template v-slot:body>
        <p>
          Hier kannst du die Anzahl des ausgewählten Artikels bearbeiten oder
          die Bezeichnung ändern.
          <br />
          Ausgewählter Artikel ist "{{ bezeichnung }}".
          <br />
          Der Artikel liegt an folgendem Ort: {{ ablageOrt }}
        </p>
        <p class="error" v-show="hasErrors">
          <b>Die Eingabe ist ungültig!</b>
        </p>
        <div class="row">
          <label for="newAnzahl" class="col-6">Anzahl:</label>
          <input
            id="newAnzahl"
            type="number"
            v-model="newAnzahl"
            class="col-6"
          />
          <label for="newBeschreibung" class="col-6">Beschreibung:</label>
          <input
            id="newBeschreibung"
            type="text"
            v-model="newBeschreibung"
            class="col-6"
          />
          <label for="newAblageOrt" class="col-6">Ablage:</label>
          <input
            id="newAblageOrt"
            type="text"
            v-model="newAblageOrt"
            class="col-6"
          />
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-danger" @click="aufgebraucht()">
          Artikel aufgebraucht
        </button>
        <button class="btn btn-primary" @click="saveAndClose()">OK</button>
      </template>
    </LkModal>
  </div>
</template>

<script>
import LkNavbar from "../components/LkNavbar.vue";
import LkNavbarItem from "../components/LkNavbarItem.vue";
import LkFooter from "../components/LkFooter.vue";
import LkTable from "../components/LkTable.vue";
import LkTableHeaderCell from "../components/LkTableHeaderCell.vue";
import LkTableBodyRow from "../components/LkTableBodyRow.vue";
import LkTableBodyCell from "../components/LkTableBodyCell.vue";
import LkModal from "../components/LkModal.vue";

import inventarApi from "../apis/inventarApi";
import objektApi from "../apis/objectApi";

export default {
  name: "startpage",
  components: {
    LkNavbar,
    LkNavbarItem,
    LkFooter,
    LkTable,
    LkTableHeaderCell,
    LkTableBodyRow,
    LkTableBodyCell,
    LkModal,
  },
  data() {
    return {
      alleArtikel: null,
      responseData: null,
      searchInput: "",
      width: 0,
      showModal: false,
      bezeichnung: "",
      ablageOrt: "",
      anzahl: 0,
      anzahlId: 0,
      newAnzahl: 0,
      hasErrors: false,
      newBeschreibung: "",
      newAblageOrt: "",
    };
  },
  methods: {
    getAllArtikel() {
      inventarApi.getAll().then((response) => {
        this.alleArtikel = [];
        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].anzahl != "0") {
            console.log("gefunden!");
            this.alleArtikel.push(response.data[i]);
          } else {
            console.log("nicht gefunden!");
          }
        }
        this.alleArtikel.sort(function (a, b) {
          if (a.objekt == null) {
            return 1;
          } else if (b.objekt == null) {
            return -1;
          }
          a = a.objekt.bezeichnung.toLowerCase();
          b = b.objekt.bezeichnung.toLowerCase();
          if (a < b) {
            return -1;
          } else {
            return 1;
          }
        });
      });
    },
    isSmartphone() {
      if (this.width < 992) {
        return true;
      } else {
        return false;
      }
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    showAnzahlModal(artikel) {
      this.anzahl = artikel.anzahl;
      this.anzahlId = artikel.barcode;
      this.newAnzahl = this.anzahl;
      this.showModal = true;
      if (artikel.objekt) {
        this.bezeichnung = artikel.objekt.bezeichnung;
        this.ablageOrt = artikel.objekt.ablageOrt;
        this.newBeschreibung = artikel.objekt.bezeichnung;
        this.newAblageOrt = artikel.objekt.ablageOrt;
      }
    },
    saveAndClose() {
      //Anzahl ändern
      let madeRequest = false;
      if (this.newAnzahl != "" && this.newAnzahl != this.anzahl) {
        let newAnzahlInt = parseInt(this.newAnzahl, 10);
        if (!isNaN(newAnzahlInt)) {
          madeRequest = true;
          inventarApi
            .updateForId(this.anzahlId, newAnzahlInt)
            .then((response) => {
              console.log(response);
              this.showModal = false;
              this.getAllArtikel();
            });
        } else {
          this.hasErrors = true;
        }
      }
      //Objekt ändern
      if (
        (this.newBeschreibung != "" &&
          this.newBeschreibung != this.bezeichnung) ||
        (this.newAblageOrt != "" && this.newAblageOrt != this.ablageOrt)
      ) {
        madeRequest = true;
        objektApi
          .updateObjekt(this.anzahlId, this.newBeschreibung, this.newAblageOrt)
          .then((response) => {
            console.log(response);
            this.showModal = false;
            this.getAllArtikel();
          })
          .catch((error) => {
            if (error.response.status == 404) {
              objektApi
                .createNew(
                  this.anzahlId,
                  this.newBeschreibung,
                  this.newAblageOrt
                )
                .then((response) => {
                  console.log(response);
                  this.showModal = false;
                  this.getAllArtikel();
                });
            }
          });
      }
      if (!madeRequest) {
        console.log("Keine Änderungen!");
        this.showModal = false;
      }
    },
    aufgebraucht() {
      inventarApi.updateForId(this.anzahlId, "0").then((response) => {
        console.log(response);
        this.showModal = false;
        this.getAllArtikel();
      });
    },
  },
  computed: {
    filteredArtikel() {
      var filter = [];
      if (this.searchInput === "") {
        filter = this.alleArtikel;
      } else {
        for (var i = 0; i < this.alleArtikel.length; i++) {
          if (
            this.alleArtikel[i].objekt.bezeichnung
              .toLowerCase()
              .includes(this.searchInput.toLowerCase())
          ) {
            filter.push(this.alleArtikel[i]);
          }
        }
      }
      return filter;
    },
  },
  mounted() {
    this.getAllArtikel();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.btn-circle.btn-md {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
  text-align: center;
}

.error {
  color: red;
}
</style>
