<template>
  <div>
    <label v-if="$slots.default" :for="id">
      <!--@slot Label-Slot -->
      <slot></slot>
    </label>
    <input
      :id="id"
      :type="type"
      class="form-control"
      :value="value"
      @input="onInput"
      v-bind="attrs"
      v-on="listeners"
    />
    <small :id="id + 'helper'" class="form-text text-muted">
      <!--@slot Helper-Slot-->
      <slot name="helper"></slot>
    </small>
  </div>
</template>

<script>
export default {
  name: "LkInput",
  props: {
    /**
     * Id für Input + Label
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Type für Input
     */
    type: {
      type: String,
    },
    /**
     * Prop für v-model
     */
    value: {},
  },
  computed: {
    listeners() {
      //eslint-disable-next-line
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      //eslint-disable-next-line
      const { value, id, type, ...attrs } = this.$attrs;
      return attrs;
    },
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style>
</style>