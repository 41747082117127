<template>
  <div>
    <div class="row my-3">
      <LkInput id="manual Number" class="offset-1 col-7" v-model="manualNumber"
        >Art.-Nr.
        <template v-slot:helper>
          <p v-if="manualError" class="error">Bitte eine Nummer eingeben!</p></template
        >
      </LkInput>
      <LkButton class="offset-1 col-2 btn-primary" @click="logItManual"
        >OK</LkButton
      >
    </div>
    <v-quagga
      v-if="showBarcodereader"
      :onDetected="logIt"
      :readerSize="readerSize"
      :readerTypes="['ean_reader']"
    ></v-quagga>
    Potential Code: {{ potentialCode }}<br />
    Stabilizer: {{ stabilizer }}<br />
    <LkModal v-if="showModal">
      <template v-slot:header>
        <h3>Unbekannter Artikel</h3>
      </template>
      <template v-slot:body>
        <p>
          Der Artikel ist nicht bekannt, bitte füge eine Bezeichnung und einen
          Ablageort hinzu
        </p>
        <p class="error" v-if="isEmpty">
          <b>Bitte ausfüllen!</b>
        </p>
        <p class="error" v-if="isBackendError">
          <b>Ein Fehler im Backend! Bitte Lukas kontaktieren!</b>
        </p>
        <div class="row">
          <label for="bezeichnung" class="col-8">Bezeichnung:</label>
          <input
            id="bezeichnung"
            type="text"
            v-model="newBezeichnung"
            class="col-12"
          />
          <label for="ablageort" class="col-8">Ablageort:</label>
          <input
            id="ablageort"
            type="text"
            v-model="newAblageort"
            class="col-12"
          />
        </div>
        <br />
      </template>
      <template v-slot:footer>
        <button class="modal-default-button" @click="saveObjektAndClose">
          OK
        </button>
      </template>
    </LkModal>
    <LkModal v-if="showObjectModal">
      <template v-slot:header>
        <h3>Artikel gefunden!</h3>
      </template>
      <template v-slot:body>
        <p>
          Der Artikel ist schon unter {{modalBezeichnung}} bekannt. Die Anzahl des Artikels wurde auf {{modalAnzahl}} erhöht.
        </p>
        <br />
      </template>
      <template v-slot:footer>
        <button class="modal-default-button" @click="goToStartseite()">
          Zurück zur Startseite
        </button>
      </template>
    </LkModal>
  </div>
</template>

<script>
import LkModal from "../components/LkModal.vue";
import LkInput from "../components/LkInput.vue";
import LkButton from "../components/LkButton.vue";
import VueQuagga from "vue-quaggajs";
import Vue from "vue";

Vue.use(VueQuagga);

import inventarApi from "../apis/inventarApi";
import objektApi from "../apis/objectApi";

export default {
  name: "scanner",
  components: {
    LkModal,
    LkInput,
    LkButton,
  },
  data() {
    return {
      showModal: false,
      showObjectModal: false,
      modalAnzahl: 0,
      modalBezeichnung: "",
      newBezeichnung: "",
      newAblageort: "",
      isEmpty: false,
      isBackendError: false,
      readerSize: {
        width: 640,
        height: 480,
      },
      dataCode: "null",
      showBarcodereader: true,
      potentialCode: "",
      stabilizer: 0,
      manualNumber: "",
      manualError: false,
    };
  },
  computed:{
    isSmartphone() {
      if (this.width < 992) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    addProduct() {
      inventarApi.existsById(this.dataCode).then((response) => {
        if (response.data == false) {
          inventarApi.createNew(this.dataCode, "1").then((response) => {
            if (response.data.objekt == null) {
              this.showModal = true;
            } else {
              this.goToStartseite();
            }
          });
        } else {
          inventarApi.getInstance(this.dataCode).then((response) => {
            inventarApi
              .updateForId(this.dataCode, parseInt(response.data.anzahl) + 1)
              .then((response) => {
                this.modalAnzahl = response.data.anzahl;
                this.modalBezeichnung = response.data.objekt.bezeichnung;
                this.showObjectModal = true;
              });
          });
        }
      });
    },
    saveObjektAndClose() {
      if (this.newBezeichnung == "" || this.newAblageort == "") {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
        objektApi
          .createNew(this.dataCode, this.newBezeichnung, this.newAblageort)
          .then((response) => {
            if (response.status == 200) {
              this.isBackendError = false;
              this.showModal = false;
              this.goToStartseite();
            } else {
              this.isBackendError = true;
            }
          });
      }
    },
    goToStartseite() {
      this.showObjectModal = false;
      this.$router.push("/");
    },
    logItManual() {
      if (this.manualNumber && this.manualNumber != "") {
        this.dataCode = this.manualNumber;
        this.manualNumber = "";
        this.manualError = false;
        this.showBarcodereader = false;
        this.addProduct();
      }else{
        this.manualError = true;
      }
    },
    logIt(data) {
      if (data.codeResult.code === this.potentialCode && this.stabilizer < 6) {
        this.stabilizer++;
      } else if (data.codeResult.code !== this.potentialCode) {
        this.stabilizer = 0;
        this.potentialCode = data.codeResult.code;
      } else {
        this.potentialCode = "";
        this.stabilizer = 0;
        this.dataCode = data.codeResult.code;
        this.showBarcodereader = false;
        this.addProduct();
      }
    },
  },
};
</script>

<style>
.error {
  color: red;
}
</style>
