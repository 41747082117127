<template>
  <tr>
    <!-- @slot Table-Body-Row-Inhalt -->
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "LkTableBodyRow"
};
</script>

<style>
</style>