import Vue from 'vue'
import Router from 'vue-router'
import startpage from './startpage.vue'
import scanner from './scanner.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'startpage',
      component: startpage
    },
    {
      path: '/addProduct',
      name: "Scanner",
      component: scanner
    }
  ]
})
