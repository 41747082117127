<template>
  <li class="nav-item">
    <!-- @slot Slot für Inhalt des Navbar-Items -->
    <slot></slot>
  </li>
</template>

<script>
export default {
    name: "LkNavbarItem"
};
</script>

<style>
</style>