<template>
  <nav class="navbar navbar-expand-lg sticky-top navbar-light bg-light">
    <a class="navbar-brand" href="#" @click.prevent="emitClick()">
      <!-- @slot Navbar-Headline -->
      <slot name="headline"></slot>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <!-- @slot Slot für Navbar-Items -->
        <slot name="navbar-items"></slot>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "LkNavbar",
  methods:{
    emitClick(){
      this.$emit("header-click", null);
    }
  }
};
</script>

<style>
</style>