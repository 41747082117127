<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <!-- @slot Table-Header -->
        <slot name="table-header"></slot>
      </tr>
    </thead>
    <tbody>
      <!-- @slot Table-Body -->
      <slot name="table-body"></slot>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "LkTable",
};
</script>

<style>
</style>