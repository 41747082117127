<template>
  <th>
    <!-- @slot Table-Header-Cell-Inhalt -->
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "LkTableHeaderCell"
};
</script>

<style>
</style>