<template>
  <footer>
      <!-- @slot Slot für Footer-Inhalt -->
      <slot></slot>
  </footer>
</template>

<script>
export default {
    name: "LkFooter"
}
</script>

<style>
footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}
</style>