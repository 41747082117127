<template>
  <button v-on="$listeners" v-bind="$attrs" class="btn">
    <!--@slot Label für Button-->
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "LkButton"
};
</script>

<style>
</style>