import axios from 'axios';

export default{
    createNew: (artikelId, anzahl) => axios.post('inventarapi/createArtikel', {barcode: artikelId, anzahl: anzahl}),
    getAll: () => axios.get('inventarapi/getAll', {
        transformResponse: [function(data){
            return JSON.parse(data);
        }]
    }),
    updateForId: (id, anzahl) => axios.put('inventarapi/updateArtikel/'+id, {barcode: id, anzahl: anzahl}),
    existsById: (id) => axios.get('inventarapi/exists/'+id),
    getInstance: (id) => axios.get('inventarapi/getInstance/'+id)
}